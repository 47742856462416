import React from "react"

import SolidHero from "../components/solid-hero/solid-hero"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Accordion from "../components/accordion/accordion"
import ImageWithText from "../components/image-with-text/image-with-text"
import ContactAndAddressTile from "../components/contact-and-address-tile/contact-and-address-tile"

const ASFSeminarPage = ({data}) => (
  <Layout>
    <SEO title="ASF-Seminar" description={'Unfreiwillige Punktlandung in Flensburg gemacht? Gemeinsam machen wir dich wieder verkehrsfit – im ASF-Seminar bei deiner Backnanger Fahrschule Rauscher.'}/>
    <SolidHero primary title={"ASF-Seminar"} subtitle={() => <>Der Weg zurück hinter's Steuer.</>}></SolidHero>
    <ImageWithText textLength={'long'} title={"Wir machen dich wieder Verkehrstauglich."} linkPath={"/anmeldung/"}
                   linkText={"Melde dich jetzt an"}
                   body={() => <>Hier treffen sich vorrangig die Bad Boys & Girls: Das Aufbauseminar. Unter
                     Fahrschüler*innen ist es zwar in der Regel gefürchtet, kann aber in der Probezeit durchaus
                     mal passieren. Wobei nicht jeder Verstoß zwangsweise sofort zum ASF-Seminar führt, hier
                     wird zwischen leichten und schweren Vergehen unterschieden. Hartnäckig an der Spitze hält
                     sich bei Fahranfänger*innen übrigens überhöhte Geschwindigkeit – das Nummer-Eins-Ticket
                     fürs Aufbauseminar. Was auch immer es bei dir ist (ganz ohne Grund liest du diesen Text ja
                     nicht), wir kriegen dich sicher wieder verkehrstauglich.<br/><br/><b>Tatsächlich einen Brief bekommen?</b><br/>
                     Dann erwartet dich folgender Ablauf: Zunächst einmal herrscht beim ASF-Seminar in der
                     Regel eine entspannte, fast schon gruppentherapeutische Atmosphäre, schließlich gibt‘s hier
                     selten Schwerverbrecher*innen, sondern viel eher Menschen, die im Straßenverkehr einen
                     großen oder mehrere kleine Fehler begangen haben. Gleichzeitig bringen alle eine riesige
                     Portion Eigenmotivation mit – immerhin will jede*r die Fahrerlaubnis behalten. Neben den
                     theoretischen Gesprächsrunden stehen Beobachtungsfahrten auf dem Programm, hier
                     geht’s darum, sich gegenseitig zu beurteilen und die Fahrweise der anderen zu analysieren.
                     So arbeitet ihr als Gruppe miteinander und baut euch gegenseitig auf – um nach
                     abgeschlossenem Seminar wieder erfolgreich sichere Verkehrsteilnehmer*innen zu sein.</>}
                   imageAlt={"Dank ASF-Seminar zurück auf die Straße."}
                   imageFluid={data.file.childImageSharp.fluid}></ImageWithText>
    <ContactAndAddressTile></ContactAndAddressTile>
    <Accordion questionsAnswers={[{
      question: "Wie lange dauert die Probezeit?",
      answer: () => <>Die Probezeit beginnt mit Erteilung des Führerscheins und endet nach zwei Jahren, sofern du
        während deiner Probezeit keine Auffälligkeiten zeigst.</>,
    }, { question: "Was passiert, wenn ich während meiner Probezeit im Straßenverkehr auffällig werde?",
      answer: () => <>In der Regel, wird je nach Verkehrsvergehen ein Aufbauseminar (Nachschulung) angeordnet. Außerdem
        verlängert sich die Probezeit auf weitere 2 Jahre, auf insgesamt 4 Jahre. Solltest du nicht am vorgeschriebenen
        Seminar teilnehmen oder die Festgelegte Frist versäumen, wird die Fahrerlaubnis entzogen.</>,
    }, { question: "Wie läuft das Aufbau Seminar ab?", answer: () => <>Das Seminar kann ca. 2 bis 4 Wochen dauern. Darin enthalten sind 4 Sitzungen je 135 Min. und eine Fahrprobe.</> }]}>
    </Accordion>
  </Layout>
)

export default ASFSeminarPage
export const query = graphql`
  query ASFSeminarPageQuery {
    file(relativePath: { eq: "asf-seminar-lifestyle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
